import {DataExtensionsService, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsApi} from '../apis/CheckoutSettingsApi';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {CheckoutOrigin} from '../utils/bi.util';
import {FORMS_CHECKOUT_DEXT_FQDN, FORMS_TEMPLATE_IDS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {getFieldPropertiesByTarget} from '@wix/form-viewer/controller';
import {StoreCheckoutSettingsAndPayments} from '../utils/types.util';
import {toMonitorError} from '../utils/errorMonitor.utils';
import {CountryCode} from '@wix/form-fields';
import {FormFieldTarget} from '../../types/app.types';

export const USER_DEFINED_FIELDS_NAMESPACE = '_user_fields';
export class CheckoutSettingsService {
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsApi: CheckoutSettingsApi;
  private readonly dataExtensionsService: DataExtensionsService;
  public checkoutSettings!: CheckoutSettingsModel;
  public showCouponSP!: boolean;
  public showExpressCheckoutSP!: boolean;
  public doesFastFlowFormHaveFieldsToDisplay!: boolean;
  private readonly flowAPI: ControllerFlowAPI;

  constructor({
    controllerConfig,
    siteStore,
    flowAPI,
    dataExtensionsService,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    flowAPI: ControllerFlowAPI;
    dataExtensionsService: DataExtensionsService;
  }) {
    this.controllerConfig = controllerConfig;
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.dataExtensionsService = dataExtensionsService;
    this.checkoutSettingsApi = new CheckoutSettingsApi({siteStore, origin: CheckoutOrigin.checkout});
  }

  public async fetchSiteSettings(): Promise<void> {
    const checkoutSettingsAndPayments: StoreCheckoutSettingsAndPayments =
      await this.checkoutSettingsApi.getCheckoutSettings();
    this.checkoutSettings = new CheckoutSettingsModel({
      checkoutSettingsAndPayments,
      taxName: this.siteStore.taxName,
    });
  }

  public async fetchDoesFastFlowFormHaveFieldsToDisplay(): Promise<boolean> {
    const extendedFieldsFieldProps = await getFieldPropertiesByTarget({
      options: {fqdn: FORMS_CHECKOUT_DEXT_FQDN},
      httpClient: this.flowAPI.essentials.httpClient,
      formId: FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
    });

    const otherFieldProps = await getFieldPropertiesByTarget({
      httpClient: this.flowAPI.essentials.httpClient,
      formId: FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
    });

    const fieldValues = extendedFieldsFieldProps ? Object.values(extendedFieldsFieldProps) : [];
    /* istanbul ignore next */
    const doesFormHaveExtendedFields = fieldValues.filter((fieldValue) => fieldValue?.hidden === false).length > 0;
    const doesFormHaveAdditionalInfoField =
      otherFieldProps &&
      /* istanbul ignore next */ otherFieldProps[FormFieldTarget.additional_info_1]?.hidden === false;
    const doesFastFlowFormHaveFieldsToDisplay = doesFormHaveExtendedFields || Boolean(doesFormHaveAdditionalInfoField);
    this.doesFastFlowFormHaveFieldsToDisplay = doesFastFlowFormHaveFieldsToDisplay;
    return doesFastFlowFormHaveFieldsToDisplay;
  }

  public async init(): Promise<void> {
    await this.fetchSiteSettings();
    await this.fetchDoesFastFlowFormHaveFieldsToDisplay();
    await this.dataExtensionsService.fetchDataExtensionsSchema().catch((e) => {
      this.flowAPI.errorMonitor.captureException(
        ...toMonitorError(e, 'CheckoutSettingsService failed to fetch data extensions schema')
      );
    });

    this.showCouponSP = this.controllerConfig.config.style.styleParams.booleans.checkout_showCoupon !== false;
    this.showExpressCheckoutSP =
      this.controllerConfig.config.style.styleParams.booleans.checkout_showExpressCheckout !== false;
  }

  public addEnabledDeliveryCountry(country: CountryCode) {
    /* istanbul ignore else */
    if (!this.checkoutSettings.enabledDeliveryCountries.includes(country)) {
      this.checkoutSettings.enabledDeliveryCountries.push(country);
    }
  }
}
